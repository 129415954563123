.App {
  text-align: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgb(233, 233, 233);
  font-family: "Inter Tight", sans-serif;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.container1 {
  flex: 0.4;
  color: white;
  background-color: rgb(53, 110, 163);
}

.container2 {
  flex: 4;
  margin-top: 1.5rem;
}

@import url("https://fonts.googleapis.com/css2?family=Inter+Tight&display=swap");
